import PropTypes from 'prop-types';
import React from 'react';

export const PinIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    focusable="false"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill-rule="evenodd"
      d="M14.5 3.75l5.75 5.75-.621.621c-.563.563-1.326.879-2.122.879h-.757L15 12.75v1.75c0 1.591-.675 3.108-1.858 4.172l-.642.578-3.546-3.547-3.088 3.15c-.192.195-.505.198-.7.007l-.004-.004c-.196-.196-.197-.513-.003-.71l3.088-3.15-3.497-3.499.577-.64C6.392 9.675 7.909 9 9.5 9h1.75L13 7.25v-.757c0-.796.316-1.56.879-2.122l.621-.621z"
    />
  </svg>
);

PinIcon.propTypes = {
  isPinned: PropTypes.bool,
};
