import PropTypes from 'prop-types';
import React from 'react';
import { getSecondaryColorNumbers } from '../../services/get-colors';
import styles from './highlighter.scss';
import { loadLazyComponent } from '@wix/communities-forum-client-commons';

const ReactHighlightWords = loadLazyComponent(
  () => import(/* webpackChunkName: "react-highlight-words-frameless" */ 'react-highlight-words'),
  {
    loading: props => <span>{props.text}</span>,
  },
);

const STYLE = {
  backgroundColor: `rgba(${getSecondaryColorNumbers()}, 0.3)`,
};

const Highlighter = ({ text, query, highlightStyle = STYLE }) => (
  <ReactHighlightWords
    highlightClassName={styles.mark}
    highlightStyle={highlightStyle}
    textToHighlight={text}
    searchWords={query.split(' ')}
    autoEscape
  />
);

Highlighter.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  highlightStyle: PropTypes.object,
};

export default Highlighter;
