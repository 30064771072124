import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import RecentPostCarousel from '../recent-posts-carousel';
import styles from './recent-posts-mobile.scss';

const RecentPostsMobile = ({ posts, isPostNumbersEnabled, handleLikeClick }) =>
  isEmpty(posts) ? null : (
    <div className={classNames(styles.container, 'forum-text-color')}>
      <RecentPostCarousel
        posts={posts}
        isPostNumbersEnabled={isPostNumbersEnabled}
        handleLikeClick={handleLikeClick}
      />
    </div>
  );

RecentPostsMobile.propTypes = {
  posts: PropTypes.array.isRequired,
  isPostNumbersEnabled: PropTypes.bool,
  handleLikeClick: PropTypes.func,
};

export default RecentPostsMobile;
