import { get, mapValues } from 'lodash';
import { connect } from '../../common/components/runtime-context';

const getSettings = (style, config) => mapValues(config, (path, prop) => get(style, path || prop));

const siteColorsReducer = (result, color) => {
  result[color.reference] = color.value;
  return result;
};

const mapRuntimeToProps = config => (state, ownProps, actions, host) => ({
  ...getSettings(host.style, config),
  siteColors: host.style.siteColors.reduce(siteColorsReducer, {}), // @todo: refactor this to with-settings-color
});

export default function withAppSettings(config) {
  return WrappedComponent => connect(mapRuntimeToProps(config))(WrappedComponent);
}
