import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import SiteLink from './site-link';
import { getNotificationsLink } from '../../../common/store/ma-navigation/ma-navigation-selectors';

const handleClick = navigateToNotifications => event => {
  event.preventDefault();
  navigateToNotifications();
};

const NotificationsLink = ({ notificationsLink, navigateToNotifications, ...props }) => {
  if (typeof window !== 'undefined' && window.__navigateWithinForumInternally) {
    props.onClick = handleClick(navigateToNotifications);
  }
  return <SiteLink to={notificationsLink} {...props} />;
};

NotificationsLink.propTypes = {
  notificationsLink: PropTypes.string,
  navigateToNotifications: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  notificationsLink: getNotificationsLink(state),
  navigateToNotifications: actions.navigateToNotifications,
});

export default connect(mapRuntimeToProps, [REDUCERS.MA_NAVIGATION])(NotificationsLink);
