import React from 'react';

export const ModeratorSmallIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    viewBox="0 0 19 19"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path d="M9.38865493,13.0966675 L5.99777689,15.3695159 L5.99777689,15.3695159 C5.81427229,15.492516 5.56580117,15.4434673 5.44280113,15.2599627 C5.37531373,15.1592778 5.35712143,15.0334221 5.39333431,14.9177474 L6.61881162,11.00321 L3.26052421,8.54484297 L3.26052421,8.54484297 C3.0822674,8.41435365 3.04354421,8.16406547 3.17403353,7.98580866 C3.24768718,7.88519303 3.36404913,7.82468064 3.48871678,7.82216208 L7.71933169,7.73669425 L9.12378621,3.84718484 L9.12378621,3.84718484 C9.19881412,3.63940187 9.42807763,3.53178262 9.63586061,3.60681052 C9.74774042,3.64720896 9.83583649,3.73530503 9.87623493,3.84718484 L11.2806895,7.73669425 L15.5113044,7.82216208 L15.5113044,7.82216208 C15.7321732,7.82662412 15.9076056,8.00929089 15.9031435,8.23015972 C15.900625,8.35482737 15.8401126,8.47118932 15.7394969,8.54484297 L12.3812095,11.00321 L13.6066868,14.9177474 L13.6066868,14.9177474 C13.6726871,15.1285718 13.5552839,15.3529825 13.3444596,15.4189828 C13.2287849,15.4551956 13.1029292,15.4370033 13.0022442,15.3695159 L9.61136621,13.0966675 L9.61136621,13.0966675 C9.54399616,13.0515105 9.45602499,13.0515105 9.38865493,13.0966675 Z" />
  </svg>
);
