import { getListLayoutId, getListNumberOfPosts } from './app-settings-selectors';
import { getLayoutName as getLayoutNameById } from '../constants/layouts';
import {
  getLayoutConfig as getLayoutConfigByName,
  FONT_COLOR_KEYS,
} from '../services/layout-config';
import { LIST_NUMBER_OF_POSTS } from '@wix/communities-forum-client-commons';

export const getLayoutName = ({ state, style }) => {
  return getLayoutNameById(getListLayoutId(state, style));
};

export const getLayoutConfig = ({ state, isMobile, style }) =>
  getLayoutConfigByName(getLayoutName({ state, isMobile, style }));

export const getFontAndColorKeys = () => FONT_COLOR_KEYS;

export const getPostsPerPage = (state, style) => {
  const layoutConfig = getLayoutConfig({ state, style });
  const settingsKey = layoutConfig.postsNumberSettingsKey;

  if (settingsKey === LIST_NUMBER_OF_POSTS) {
    return getListNumberOfPosts(state, style);
  } else {
    throw new Error(`Invalid posts number settings key ${settingsKey}`);
  }
};
