import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './time-ago.scss';
import withRelativeTimeFormatting from '../../hoc/with-relative-time-formatting';

const weekAgo = () => new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
const isLessThanWeek = time => new Date(time).getTime() > weekAgo();
const isNotCurrentYear = time => new Date().getYear() !== new Date(time).getYear();
const getTimeAgoFormat = time => {
  if (isLessThanWeek(time)) {
    return 'time-relative';
  }
  if (isNotCurrentYear(time)) {
    return 'fullDate';
  }
  return 'date';
};

const normalizeTime = time => {
  const currentDate = new Date();
  return new Date(time).getTime() > currentDate.getTime() ? currentDate : new Date(time);
};

const TimeAgo = ({ className, t, time, formatRelativeTime }) => {
  let formattedTime;
  const format = getTimeAgoFormat(time);

  if (format === 'time-relative') {
    formattedTime = formatRelativeTime(time);
  } else {
    formattedTime = t(format, { time: normalizeTime(time) });
  }

  return (
    <span className={classNames(styles.timeAgo, className, 'time-ago')} data-hook="time-ago">
      {formattedTime}
    </span>
  );
};

TimeAgo.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func,
  time: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
};

export default flowRight(withTranslate, withRelativeTimeFormatting)(TimeAgo);
