import React from 'react';
import PropTypes from 'prop-types';

export const SolvedIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      d="M12 21c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9zm-.8-8l-2-2L8 12.2l3.2 3.3 5.3-5.3L15.2 9l-4 4z"
    />
  </svg>
);

SolvedIcon.propTypes = {
  isColored: PropTypes.bool,
};
