import { getCommentsPerPage, REPLIES_PER_PAGE } from '../constants/pagination';
import { URI, EXPERIMENT_URL_CUSTOMIZATION } from '@wix/communities-forum-client-commons';
import getOuterUrl from './get-outer-url';
import { buildCustomizedUrl } from './build-customized-url';

import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { getSectionUrl } from '../../common/store/location/location-selectors';

const getPage = (index, countPerPage) => Math.max(Math.ceil((index + 1) / countPerPage), 1); // + 1 because it's index and we need to make it position

export const buildDeepCommentUrl = ({
  categorySlug,
  postSlug,
  commentIndex,
  parentIndex,
  parentId,
  commentId,
  isMobile,
  url,
}) => {
  const query = new URI(url).query();
  const queryString = query ? `?${query}` : '';

  return `/${categorySlug}/${postSlug}/p-${getPage(
    parentId ? parentIndex : commentIndex,
    getCommentsPerPage(isMobile),
  )}/dl-${commentId}${parentId ? `-${parentId}` : ''}${
    parentId ? `-${getPage(commentIndex, REPLIES_PER_PAGE)}` : ''
  }${queryString}`;
};

export const buildNewDeepCommentUrl = (
  categorySlug,
  postSlug,
  commentId,
  url = '',
  searchTerm = '',
) => {
  const query = new URI(url).query();
  const queryString = query ? `?${query}${searchTerm ? '&' + searchTerm : ''}` : `?${searchTerm}`;

  return `/${categorySlug}/${postSlug}/dl-${commentId}${queryString}`;
};

export const buildDeepLink = (state, commentId, postId) => {
  const path = `/main/comment/${commentId}`;
  const sectionUrl = getSectionUrl(state);
  const customizedUrl =
    isExperimentEnabled(state, EXPERIMENT_URL_CUSTOMIZATION) && buildCustomizedUrl(state, path);
  const url = URI(customizedUrl || getOuterUrl(path, sectionUrl));
  if (postId) {
    url.setQuery({ postId });
  }
  return url.toString();
};
