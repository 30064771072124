import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const handleEventNoop = ev => {
  ev.stopPropagation();
  ev.preventDefault();
};

const NoopLink = ({ children, ...props }) => {
  const elementProps = omit(props, 'user', 'tReady');
  return (
    <span {...elementProps} onClick={handleEventNoop}>
      {children}
    </span>
  );
};

NoopLink.propTypes = {
  children: PropTypes.node,
};

export default NoopLink;
