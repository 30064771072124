import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import Link from '../link/internal-link';
import IconTooltip from '../icon-tooltip';
import styles from './best-answer-icon-link.scss';
import { SolvedIcon } from '../icons/solved-icon';
import { buildNewDeepCommentUrl } from '../../services/build-deep-comment-url';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons';
import { getCategory } from '../../../common/selectors/categories-selectors';

const BestAnswerIconLink = ({
  t,
  toolTipClassName,
  toolTipContent,
  iconClassName,
  linkComponent: LinkComponent,
  resolveCommentDeepLinkUrl,
  commentId,
  isWixCommentsEnabled,
  post,
  categorySlug,
}) => (
  <IconTooltip text={toolTipContent || t('comment.solved')} className={toolTipClassName}>
    <LinkComponent
      dynamicLocationResolver={
        isWixCommentsEnabled ? null : () => resolveCommentDeepLinkUrl(commentId)
      }
      className={styles.link}
      to={
        isWixCommentsEnabled
          ? buildNewDeepCommentUrl(categorySlug, post.slug, commentId)
          : `/main/comment/${commentId}`
      }
      aria-label={t('comment.solved.label')}
    >
      <SolvedIcon className={classNames(iconClassName, 'button-fill')} />
    </LinkComponent>
  </IconTooltip>
);

BestAnswerIconLink.propTypes = {
  commentId: PropTypes.string, // TODO: remove this prop and all usage when merging specs.comments.EnableForumNewWixComments
  toolTipClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  linkComponent: PropTypes.any,
  resolveCommentDeepLinkUrl: PropTypes.func,
  t: PropTypes.func,
  isWixCommentsEnabled: PropTypes.bool,
  post: PropTypes.object,
  categorySlug: PropTypes.string,
};

BestAnswerIconLink.defaultProps = {
  linkComponent: Link,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const category = getCategory(state, ownProps.post.categoryId) || {};
  return {
    resolveCommentDeepLinkUrl: actions.resolveCommentDeepLinkUrlPromisified,
    categorySlug: category.slug,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({ isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS }),
)(BestAnswerIconLink);
