import { map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import RecentPostsListItem from '../recent-posts-list-item';
import { shouldRenderCover } from '../../../app/services/get-post-cover';
import styles from './recent-posts-list.scss';

export const RecentPostsList = ({ posts = [], isPostNumbersEnabled, handleLikeClick }) => {
  return (
    <div className={styles.container}>
      {map(posts, post => (
        <RecentPostsListItem
          post={post}
          isPostNumbersEnabled={isPostNumbersEnabled}
          handleLikeClick={handleLikeClick}
          withCover={shouldRenderCover(post)}
          key={post._id}
        />
      ))}
    </div>
  );
};

RecentPostsList.propTypes = {
  posts: PropTypes.array,
  isPostNumbersEnabled: PropTypes.bool,
  handleLikeClick: PropTypes.func,
};

export default RecentPostsList;
