import React from 'react';

export const MoveIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill-rule="evenodd"
      d="M12 19H7c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h9c1 0 1 1 1 1v5h-1V5H7v13h5v1zm6.293-3H13v-1h5.293l-2.145-2.144c-.195-.195-.195-.511 0-.706.195-.196.512-.196.707 0l3.352 3.35-3.352 3.354c-.195.195-.512.195-.707 0-.195-.195-.195-.512 0-.707L18.293 16z"
    />
  </svg>
);
