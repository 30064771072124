import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const useContext = Context => WrappedComponent => {
  class UseContext extends React.Component {
    static contextType = Context;
    static displayName = `useContext(${getDisplayName(WrappedComponent)})`;

    render() {
      return <WrappedComponent {...this.props} {...this.context} />;
    }
  }
  hoistNonReactStatics(UseContext, WrappedComponent);

  return UseContext;
};
