import PropTypes from 'prop-types';
import React from 'react';

export const CommentDisabledIcon = ({ title, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path
      fill-rule="evenodd"
      d="M3.727 4.055l.077.048 1.394 1.065C5.443 5.06 5.715 5 6 5h12c1.104 0 2 .896 2 2v8c0 .445-.146.856-.392 1.189l1.196.914c.22.168.26.481.093.7-.149.196-.413.25-.624.142l-.077-.048-1.393-1.065c-.246.108-.517.168-.803.168h-4.36l-4.884 2.93c-.079.047-.168.07-.256.07-.086 0-.17-.021-.247-.065C8.097 19.846 8 19.68 8 19.5V17H6c-1.104 0-2-.896-2-2V7c0-.445.146-.856.392-1.189l-1.196-.914c-.22-.168-.26-.481-.093-.7.149-.196.413-.25.624-.142zM5 7v8c0 .553.447 1 1 1h3v2.621L13.36 16h4.355L5.185 6.419C5.07 6.582 5 6.783 5 7zm13-1H6.285l12.53 9.581c.116-.163.185-.364.185-.581V7c0-.553-.447-1-1-1z"
    />
  </svg>
);

CommentDisabledIcon.propTypes = {
  title: PropTypes.string,
};
