import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../common/components/runtime-context';
import hoistNonReactStatics from 'hoist-non-react-statics';
import preventClickInDemoMode from './prevent-click-in-demo-mode';
import { createHocName } from '@wix/communities-forum-client-commons';
import withAuth from './with-auth';
import { keepFocus } from '../services/keep-focus';

const NAME = 'EnsureAuth';

export default function ensureAuth(WrappedComponent) {
  const ChildComponent = preventClickInDemoMode(WrappedComponent);

  class EnsureAuth extends Component {
    static displayName = createHocName(NAME, WrappedComponent);

    handleClick = event => {
      const {
        isAuthenticated,
        onClick,
        requestLogin,
        requestLoginMode,
        beforeRequestLogin,
        setSavedAction,
        actionDetails,
        reportBI,
      } = this.props;
      reportBI && reportBI();
      if (isAuthenticated) {
        onClick && onClick(event);
      } else {
        event.preventDefault();
        event.stopPropagation();
        if (beforeRequestLogin) {
          beforeRequestLogin();
        }
        actionDetails && setSavedAction(actionDetails);
        requestLogin(requestLoginMode);
      }
    };

    render() {
      const otherProps = omit(this.props, ['isAuthenticated', 'onClick', 'requestLogin']);
      return <ChildComponent {...otherProps} onClick={this.handleClick} />;
    }
  }

  EnsureAuth.propTypes = {
    requestLogin: PropTypes.func.isRequired,
    requestLoginMode: PropTypes.oneOf(['login', 'signup']).isRequired,
    isAuthenticated: PropTypes.bool,
    onClick: PropTypes.func,
    beforeRequestLogin: PropTypes.func,
    actionDetails: PropTypes.object,
    setSavedAction: PropTypes.func.isRequired,
  };

  hoistNonReactStatics(EnsureAuth, ChildComponent);

  const mapRuntimeToProps = (state, ownProps, actions) => ({
    requestLogin: keepFocus(actions.requestLoginPromisified),
    setSavedAction: actions.setSavedAction,
  });

  return flowRight(connect(mapRuntimeToProps), withAuth)(EnsureAuth);
}
