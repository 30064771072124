// Forked from https://github.com/dai-shi/use-context-selector/blob/2dd334d727fc3b4cbadf7876b6ce64e0c633fd25/src/index.ts
// Changes: removed scheduler dependency, unused functions and added equalityFn support
import {
  ComponentType,
  Context as ContextOrig,
  FC,
  MutableRefObject,
  Provider,
  createElement,
  createContext as createContextOrig,
  useContext as useContextOrig,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
} from 'react';

import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

const CONTEXT_VALUE = Symbol();
const ORIGINAL_PROVIDER = Symbol();

const isSSR = typeof window === 'undefined';

const useIsomorphicLayoutEffect = isSSR ? useEffect : useLayoutEffect;

// for preact that doesn't have runWithPriority
const runWithNormalPriority = (thunk: () => void) => thunk();

type Version = number;

type ContextValue<Value> = {
  [CONTEXT_VALUE]: {
    /* "v"alue     */ v: MutableRefObject<Value>;
    /* versio"n"   */ n: MutableRefObject<Version>;
    /* "l"isteners */ l: Set<(action: readonly [Version] | readonly [Version, Value]) => void>;
    /* "u"pdate    */ u: (thunk: () => void) => void;
  };
};

export interface Context<Value> {
  Provider: ComponentType<{ value: Value }>;
  displayName?: string;
}

const createProvider = <Value>(
  ProviderOrig: Provider<ContextValue<Value>>,
): FC<{ value: Value }> => ({ value, children }) => {
  const valueRef = useRef(value);
  const versionRef = useRef(0);
  const contextValue = useRef<ContextValue<Value>>();
  if (!contextValue.current) {
    const listeners = new Set<(action: readonly [Version] | readonly [Version, Value]) => void>();
    const update = (thunk: () => void) => {
      batchedUpdates(() => {
        versionRef.current += 1;
        listeners.forEach(listener => listener([versionRef.current]));
        thunk();
      });
    };
    contextValue.current = {
      [CONTEXT_VALUE]: {
        /* "v"alue     */ v: valueRef,
        /* versio"n"   */ n: versionRef,
        /* "l"isteners */ l: listeners,
        /* "u"pdate    */ u: update,
      },
    };
  }
  useIsomorphicLayoutEffect(() => {
    valueRef.current = value;
    versionRef.current += 1;
    runWithNormalPriority(() => {
      (contextValue.current as ContextValue<Value>)[CONTEXT_VALUE].l.forEach(listener => {
        listener([versionRef.current, value]);
      });
    });
  }, [value]);
  return createElement(ProviderOrig, { value: contextValue.current }, children);
};

const identity = <T>(x: T) => x;

/**
 * This creates a special context for `useContextSelector`.
 *
 * @example
 * import { createContext } from 'use-context-selector';
 *
 * const PersonContext = createContext({ firstName: '', familyName: '' });
 */
export function createContext<Value>(defaultValue: Value) {
  const context = createContextOrig<ContextValue<Value>>({
    [CONTEXT_VALUE]: {
      /* "v"alue     */ v: { current: defaultValue },
      /* versio"n"   */ n: { current: -1 },
      /* "l"isteners */ l: new Set(),
      /* "u"pdate    */ u: f => f(),
    },
  });
  ((context as unknown) as {
    [ORIGINAL_PROVIDER]: Provider<ContextValue<Value>>;
  })[ORIGINAL_PROVIDER] = context.Provider;
  ((context as unknown) as Context<Value>).Provider = createProvider(context.Provider);
  delete (context as any).Consumer; // no support for Consumer
  return (context as unknown) as Context<Value>;
}

/**
 * This hook returns context selected value by selector.
 *
 * It will only accept context created by `createContext`.
 * It will trigger re-render if only the selected value is referentially changed.
 *
 * The selector should return referentially equal result for same input for better performance.
 *
 * @example
 * import { useContextSelector } from 'use-context-selector';
 *
 * const firstName = useContextSelector(PersonContext, state => state.firstName);
 */
export function useContextSelector<Value, Selected>(
  context: Context<Value>,
  selector: (value: Value) => Selected,
  equalityFn: (v1: any, v2: any) => boolean,
) {
  const contextValue = useContextOrig((context as unknown) as ContextOrig<ContextValue<Value>>)[
    CONTEXT_VALUE
  ];
  if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
    if (!contextValue) {
      throw new Error('useContextSelector requires special context');
    }
  }
  const {
    /* "v"alue     */ v: { current: value },
    /* versio"n"   */ n: { current: version },
    /* "l"isteners */ l: listeners,
  } = contextValue;
  const selected = selector(value);
  const [state, dispatch] = useReducer(
    (
      prev: readonly [Value, Selected],
      next?: // undefined from render below
      | readonly [Version] // from useContextUpdate
        | readonly [Version, Value], // from provider effect
    ) => {
      if (!next) {
        return [value, selected] as const;
      }
      if (next[0] <= version) {
        if (equalityFn(prev[1], selected)) {
          return prev; // bail out
        }
        return [value, selected] as const;
      }
      try {
        if (next.length === 2) {
          if (equalityFn(prev[0], next[1])) {
            return prev; // do not update
          }
          const nextSelected = selector(next[1]);
          if (equalityFn(prev[1], nextSelected)) {
            return prev; // do not update
          }
          return [next[1], nextSelected] as const;
        }
      } catch (e) {
        // ignored (stale props or some other reason)
      }
      return [...prev] as const; // schedule update
    },
    [value, selected] as const,
  );
  if (!equalityFn(state[1], selected)) {
    // schedule re-render
    // this is safe because it's self contained
    dispatch();
  }
  useIsomorphicLayoutEffect(() => {
    listeners.add(dispatch);
    return () => {
      listeners.delete(dispatch);
    };
  }, [listeners]);
  return state[1];
}

/**
 * This hook returns the entire context value.
 * Use this instead of React.useContext for consistent behavior.
 *
 * @example
 * import { useContext } from 'use-context-selector';
 *
 * const person = useContext(PersonContext);
 */
export function useContext<Value>(context: Context<Value>) {
  return useContextSelector(context, identity, Object.is);
}

/**
 * This hook returns an update function that accepts a thunk function
 *
 * Use this for a function that will change a value in
 * [Concurrent Mode](https://reactjs.org/docs/concurrent-mode-intro.html).
 * Otherwise, there's no need to use this hook.
 *
 * @example
 * import { useContextUpdate } from 'use-context-selector';
 *
 * const update = useContextUpdate();
 * update(() => setState(...));
 */

// Not needed ATM
// export function useContextUpdate<Value>(context: Context<Value>) {
//   const contextValue = useContextOrig((context as unknown) as ContextOrig<ContextValue<Value>>)[
//     CONTEXT_VALUE
//   ];
//   if (typeof process === 'object' && process.env.NODE_ENV !== 'production') {
//     if (!contextValue) {
//       throw new Error('useContextUpdate requires special context');
//     }
//   }
//   const { u: update } = contextValue;
//   return update;
// }
