import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ensureAuth from '../../hoc/ensure-auth';

class ProtectedButton extends Component {
  static propTypes = {
    tagName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    isAuthenticated: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    tagName: 'button',
  };

  render() {
    const { tagName: Comp, isDisabled, children, onClick, ...props } = this.props;
    const elementProps = omit(
      props,
      'currentUser',
      'dispatch',
      'isAuthenticated',
      'isBlocked',
      'isDemoMode',
      'isOwner',
      'openModal',
      'titleFontClassName',
      'tooltipText',
      'viewMode',
      'forPublicUser',
      'isPrivate',
      'requestLoginMode',
      'beforeRequestLogin',
    );

    return (
      <Comp {...elementProps} onClick={!isDisabled && onClick}>
        {children}
      </Comp>
    );
  }
}

export default ensureAuth(ProtectedButton);
