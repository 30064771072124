import PropTypes from 'prop-types';
import React from 'react';

export const LockIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="16"
    viewBox="0 0 13 16"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M206,388H195a1,1,0,0,1-1-1v-7a2,2,0,0,1,2-2v-1.5a4.5,4.5,0,0,1,9,0V378a2,2,0,0,1,2,2v7A1,1,0,0,1,206,388Zm-3-11.5a2.5,2.5,0,0,0-5,0V378h5v-1.5Z"
      transform="translate(-194 -372)"
    />
  </svg>
);

LockIcon.propTypes = {
  isLocked: PropTypes.bool,
};
